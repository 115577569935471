<template>
    <v-container class="down-top-padding pa-0" fluid id="normCont">

        <v-tabs
            v-model="tab"
            centered

            >
            <v-tab>
                ФП (мужчины)
            </v-tab>
            <v-tab>
                ФП (женщины)
            </v-tab>
            <v-tab
                >
                ГДЗС
            </v-tab>
            <v-tab
                >
                Проф.подготовка
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat>
                    <v-card-text >
                        <v-select
                            v-model="age"
                            :items="[6, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80]"
                            filled
                            label="Возраст"
                            ></v-select>
                        <h3>Возрастная группа: {{fpMaleGroup-1}}</h3>
                        <table>
                            <tr><th rowspan="2">Упражнения (ед. измерения)</th><th rowspan="2">Оцен-<br>ка</th><th colspan="7">Возрастная группа</th></tr>
                            <tr><th v-if='fpMaleGroup==2'>1</th><th v-if='fpMaleGroup==3'>2</th><th v-if='fpMaleGroup==4'>3</th><th v-if='fpMaleGroup==5'>4</th><th v-if='fpMaleGroup==6'>5</th><th v-if='fpMaleGroup==7'>6</th><th v-if='fpMaleGroup==8'>7</th></tr>

                            <tr><td colspan="9" bgcolor="#C8DCE1">Быстрота и ловкость</td>
                            <tr><td rowspan="3">Челночный бег 10x10 м (с)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>26</td><td v-if='fpMaleGroup==3'>27</td><td v-if='fpMaleGroup==4'>28</td><td v-if='fpMaleGroup==5'>31</td><td v-if='fpMaleGroup==6'>34</td><td v-if='fpMaleGroup==7'>36</td><td v-if='fpMaleGroup==8'>39</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>25</td><td v-if='fpMaleGroup==3'>26</td><td v-if='fpMaleGroup==4'>27</td><td v-if='fpMaleGroup==5'>30</td><td v-if='fpMaleGroup==6'>33</td><td v-if='fpMaleGroup==7'>35</td><td v-if='fpMaleGroup==8'>38</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>24</td><td v-if='fpMaleGroup==3'>25</td><td v-if='fpMaleGroup==4'>26</td><td v-if='fpMaleGroup==5'>29</td><td v-if='fpMaleGroup==6'>32</td><td v-if='fpMaleGroup==7'>34</td><td v-if='fpMaleGroup==8'>37</td></tr>

                            <tr><td rowspan="3">Подъём по штурмовой лестнице<br />на 4-й этаж (с)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>28</td><td v-if='fpMaleGroup==3'>34</td><td v-if='fpMaleGroup==4'>36</td><td v-if='fpMaleGroup==5'>38</td><td v-if='fpMaleGroup==6'>41</td><td v-if='fpMaleGroup==7'>44</td><td v-if='fpMaleGroup==8'>56</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>26</td><td v-if='fpMaleGroup==3'>28</td><td v-if='fpMaleGroup==4'>31</td><td v-if='fpMaleGroup==5'>35</td><td v-if='fpMaleGroup==6'>39</td><td v-if='fpMaleGroup==7'>41</td><td v-if='fpMaleGroup==8'>52</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>24</td><td v-if='fpMaleGroup==3'>26</td><td v-if='fpMaleGroup==4'>28</td><td v-if='fpMaleGroup==5'>32</td><td v-if='fpMaleGroup==6'>36</td><td v-if='fpMaleGroup==7'>39</td><td v-if='fpMaleGroup==8'>47</td></tr>

                            <tr><td rowspan="3">Преодоление 100-метровой полосы с препятствиями (с)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>30</td><td v-if='fpMaleGroup==3'>33</td><td v-if='fpMaleGroup==4'>36</td><td v-if='fpMaleGroup==5'>40</td><td v-if='fpMaleGroup==6'>43</td><td v-if='fpMaleGroup==7'>50</td><td v-if='fpMaleGroup==8'>60</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>28</td><td v-if='fpMaleGroup==3'>30</td><td v-if='fpMaleGroup==4'>34</td><td v-if='fpMaleGroup==5'>37</td><td v-if='fpMaleGroup==6'>40</td><td v-if='fpMaleGroup==7'>44</td><td v-if='fpMaleGroup==8'>56</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>25</td><td v-if='fpMaleGroup==3'>27</td><td v-if='fpMaleGroup==4'>32</td><td v-if='fpMaleGroup==5'>35</td><td v-if='fpMaleGroup==6'>37</td><td v-if='fpMaleGroup==7'>40</td><td v-if='fpMaleGroup==8'>50</td></tr>

                            <tr><td colspan="9" bgcolor="#C8DCE1">Сила</td></tr>
                            <tr><td rowspan="3">Подтягивание (кол-во раз)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>12</td><td v-if='fpMaleGroup==3'>10</td><td v-if='fpMaleGroup==4'>9</td><td v-if='fpMaleGroup==5'>6</td><td v-if='fpMaleGroup==6'>4</td><td v-if='fpMaleGroup==7'>3</td><td v-if='fpMaleGroup==8'>2</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>14</td><td v-if='fpMaleGroup==3'>12</td><td v-if='fpMaleGroup==4'>11</td><td v-if='fpMaleGroup==5'>8</td><td v-if='fpMaleGroup==6'>6</td><td v-if='fpMaleGroup==7'>5</td><td v-if='fpMaleGroup==8'>3</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>16</td><td v-if='fpMaleGroup==3'>14</td><td v-if='fpMaleGroup==4'>13</td><td v-if='fpMaleGroup==5'>10</td><td v-if='fpMaleGroup==6'>8</td><td v-if='fpMaleGroup==7'>7</td><td v-if='fpMaleGroup==8'>5</td></tr>

                            <tr><td rowspan="3">Наклон туловища вперед<br />(кол-во / мин.)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>50</td><td v-if='fpMaleGroup==3'>45</td><td v-if='fpMaleGroup==4'>40</td><td v-if='fpMaleGroup==5'>35</td><td v-if='fpMaleGroup==6'>30</td><td v-if='fpMaleGroup==7'>25</td><td v-if='fpMaleGroup==8'>20</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>55</td><td v-if='fpMaleGroup==3'>50</td><td v-if='fpMaleGroup==4'>45</td><td v-if='fpMaleGroup==5'>40</td><td v-if='fpMaleGroup==6'>35</td><td v-if='fpMaleGroup==7'>30</td><td v-if='fpMaleGroup==8'>25</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>60</td><td v-if='fpMaleGroup==3'>55</td><td v-if='fpMaleGroup==4'>50</td><td v-if='fpMaleGroup==5'>45</td><td v-if='fpMaleGroup==6'>40</td><td v-if='fpMaleGroup==7'>35</td><td v-if='fpMaleGroup==8'>30</td></tr>

                            <tr><td rowspan="3">Сгибание и разгибание рук в упоре лежа на полу (кол-во раз)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>50</td><td v-if='fpMaleGroup==3'>45</td><td v-if='fpMaleGroup==4'>40</td><td v-if='fpMaleGroup==5'>35</td><td v-if='fpMaleGroup==6'>30</td><td v-if='fpMaleGroup==7'>25</td><td v-if='fpMaleGroup==8'>20</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>55</td><td v-if='fpMaleGroup==3'>50</td><td v-if='fpMaleGroup==4'>45</td><td v-if='fpMaleGroup==5'>40</td><td v-if='fpMaleGroup==6'>35</td><td v-if='fpMaleGroup==7'>30</td><td v-if='fpMaleGroup==8'>25</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>60</td><td v-if='fpMaleGroup==3'>55</td><td v-if='fpMaleGroup==4'>50</td><td v-if='fpMaleGroup==5'>45</td><td v-if='fpMaleGroup==6'>40</td><td v-if='fpMaleGroup==7'>35</td><td v-if='fpMaleGroup==8'>30</td></tr>

                            <tr><td rowspan="3">Силовое<br />комплексное упражнение (кол-во повторений)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>3</td><td v-if='fpMaleGroup==3'>2</td><td v-if='fpMaleGroup==4'>1</td><td v-if='fpMaleGroup==5'>1</td><td v-if='fpMaleGroup==6'>1</td><td v-if='fpMaleGroup==7'>1</td><td v-if='fpMaleGroup==8'>1</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>4</td><td v-if='fpMaleGroup==3'>3</td><td v-if='fpMaleGroup==4'>2</td><td v-if='fpMaleGroup==5'>2</td><td v-if='fpMaleGroup==6'>2</td><td v-if='fpMaleGroup==7'>2</td><td v-if='fpMaleGroup==8'>2</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>5</td><td v-if='fpMaleGroup==3'>4</td><td v-if='fpMaleGroup==4'>3</td><td v-if='fpMaleGroup==5'>3</td><td v-if='fpMaleGroup==6'>3</td><td v-if='fpMaleGroup==7'>3</td><td v-if='fpMaleGroup==8'>3</td></tr>

                            <tr><td colspan="9" bgcolor="#C8DCE1">Выносливость</td></tr>
                            <tr><td rowspan="3">Бег на 1 км (мин. с.)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>3.40</td><td v-if='fpMaleGroup==3'>3.50</td><td v-if='fpMaleGroup==4'>4.10</td><td v-if='fpMaleGroup==5'>4.30</td><td v-if='fpMaleGroup==6'>4.40</td><td v-if='fpMaleGroup==7'>5.40</td><td v-if='fpMaleGroup==8'>5.50</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>3.25</td><td v-if='fpMaleGroup==3'>3.35</td><td v-if='fpMaleGroup==4'>3.45</td><td v-if='fpMaleGroup==5'>3.50</td><td v-if='fpMaleGroup==6'>4</td><td v-if='fpMaleGroup==7'>5</td><td v-if='fpMaleGroup==8'>5.30</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>3.10</td><td v-if='fpMaleGroup==3'>3.20</td><td v-if='fpMaleGroup==4'>3.30</td><td v-if='fpMaleGroup==5'>3.40</td><td v-if='fpMaleGroup==6'>3.50</td><td v-if='fpMaleGroup==7'>4</td><td v-if='fpMaleGroup==8'>5</td></tr>
                            <tr><td rowspan="3">Бег (кросс) на 5 км (мин.с)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>24</td><td v-if='fpMaleGroup==3'>25</td><td v-if='fpMaleGroup==4'>26</td><td v-if='fpMaleGroup==5'>31</td><td v-if='fpMaleGroup==6'>33</td><td v-if='fpMaleGroup==7'>37</td><td v-if='fpMaleGroup==8'>40</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>23</td><td v-if='fpMaleGroup==3'>24</td><td v-if='fpMaleGroup==4'>25</td><td v-if='fpMaleGroup==5'>29</td><td v-if='fpMaleGroup==6'>31</td><td v-if='fpMaleGroup==7'>35</td><td v-if='fpMaleGroup==8'>38</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>22</td><td v-if='fpMaleGroup==3'>23</td><td v-if='fpMaleGroup==4'>24</td><td v-if='fpMaleGroup==5'>27</td><td v-if='fpMaleGroup==6'>29</td><td v-if='fpMaleGroup==7'>33</td><td v-if='fpMaleGroup==8'>36</td></tr>
                            <tr><td rowspan="3">Плавание на 100 м вольным стилем (мин.с)</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>2.05</td><td v-if='fpMaleGroup==3'>2.30</td><td v-if='fpMaleGroup==4'>2.35</td><td v-if='fpMaleGroup==5'>2.55</td><td v-if='fpMaleGroup==6'>3.10</td><td v-if='fpMaleGroup==7'>3.55</td><td v-if='fpMaleGroup==8'>4.10</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>1.45</td><td v-if='fpMaleGroup==3'>2</td><td v-if='fpMaleGroup==4'>2.05</td><td v-if='fpMaleGroup==5'>2.25</td><td v-if='fpMaleGroup==6'>2.40</td><td v-if='fpMaleGroup==7'>3.05</td><td v-if='fpMaleGroup==8'>3.20</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>1.30</td><td v-if='fpMaleGroup==3'>1.45</td><td v-if='fpMaleGroup==4'>1.50</td><td v-if='fpMaleGroup==5'>2.05</td><td v-if='fpMaleGroup==6'>2.25</td><td v-if='fpMaleGroup==7'>2.40</td><td v-if='fpMaleGroup==8'>3</td></tr>
                            <tr><td rowspan="3">Лыжная гонка на 5 км (мин.с)*</td>
                                <td>«3»</td><td v-if='fpMaleGroup==2'>24</td><td v-if='fpMaleGroup==3'>26.30</td><td v-if='fpMaleGroup==4'>27.30</td><td v-if='fpMaleGroup==5'>31.30</td><td v-if='fpMaleGroup==6'>33.30</td><td v-if='fpMaleGroup==7'>36.30</td><td v-if='fpMaleGroup==8'>39.30</td></tr>
                            <tr><td>«4»</td><td v-if='fpMaleGroup==2'>23</td><td v-if='fpMaleGroup==3'>25.30</td><td v-if='fpMaleGroup==4'>26.30</td><td v-if='fpMaleGroup==5'>28.30</td><td v-if='fpMaleGroup==6'>30.30</td><td v-if='fpMaleGroup==7'>33.30</td><td v-if='fpMaleGroup==8'>36.30</td></tr>
                            <tr><td>«5»</td><td v-if='fpMaleGroup==2'>22</td><td v-if='fpMaleGroup==3'>24.30</td><td v-if='fpMaleGroup==4'>25.30</td><td v-if='fpMaleGroup==5'>26.30</td><td v-if='fpMaleGroup==6'>28.30</td><td v-if='fpMaleGroup==7'>30.30</td><td v-if='fpMaleGroup==8'>33.30</td></tr>
                        </table>
                        <p>* в бесснежных районах вместо бега на лыжах сдается кросс 5 км.</p>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                                                <v-select
                            v-model="age"
                            :items="[6, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80]"
                            filled
                            label="Возраст"
                            ></v-select>
                        <h3>Возрастная группа: {{fpFemaleGroup}}</h3>
                        <center v-if='fpFemaleGroup>=5'><h3 class='ma-7'>Женщины 5 и 6 возрастной группы проверке по физической подготовке не подлежат.</h3></center>
                        <table v-else>
                            <tr><th rowspan="2">Упражнения (ед. измерения)</th><th rowspan="2">Оцен-<br>ка</th><th colspan="4">Возрастная группа</th></tr>
                            <tr><th v-if='fpFemaleGroup==1'>1</th><th v-if='fpFemaleGroup==2'>2</th><th v-if='fpFemaleGroup==3'>3</th><th v-if='fpFemaleGroup==4'>4</th></tr>

                            <tr><td colspan="6" bgcolor="#C8DCE1">Быстрота и ловкость</td>
                            <tr><td rowspan="3">Челночный бег 10x10 м (с.)</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>36</td><td v-if='fpFemaleGroup==2'>38</td><td v-if='fpFemaleGroup==3'>44</td><td v-if='fpFemaleGroup==4'>52</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>34</td><td v-if='fpFemaleGroup==2'>36</td><td v-if='fpFemaleGroup==3'>42</td><td v-if='fpFemaleGroup==4'>50</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>32</td><td v-if='fpFemaleGroup==2'>34</td><td v-if='fpFemaleGroup==3'>40</td><td v-if='fpFemaleGroup==4'>48</td></tr>

                            <tr><td colspan="6" bgcolor="#C8DCE1">Сила</td>
                            <tr><td rowspan="3">Наклон туловища вперед (кол-во раз в минуту)</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>40</td><td v-if='fpFemaleGroup==2'>35</td><td v-if='fpFemaleGroup==3'>30</td><td v-if='fpFemaleGroup==4'>25</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>45</td><td v-if='fpFemaleGroup==2'>40</td><td v-if='fpFemaleGroup==3'>35</td><td v-if='fpFemaleGroup==4'>30</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>50</td><td v-if='fpFemaleGroup==2'>45</td><td v-if='fpFemaleGroup==3'>40</td><td v-if='fpFemaleGroup==4'>35</td></tr>

                            <tr><td rowspan="3">Силовое комплексное упражнение (количество повторений)</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>26</td><td v-if='fpFemaleGroup==2'>22</td><td v-if='fpFemaleGroup==3'>18</td><td v-if='fpFemaleGroup==4'>14</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>30</td><td v-if='fpFemaleGroup==2'>26</td><td v-if='fpFemaleGroup==3'>22</td><td v-if='fpFemaleGroup==4'>18</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>34</td><td v-if='fpFemaleGroup==2'>30</td><td v-if='fpFemaleGroup==3'>26</td><td v-if='fpFemaleGroup==4'>22</td></tr>

                            <tr><td colspan="6" bgcolor="#C8DCE1">Выносливость</td></tr>
                            <tr><td rowspan="3">Бег на 1 км (мин. с.)</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>4.35</td><td v-if='fpFemaleGroup==2'>5.10</td><td v-if='fpFemaleGroup==3'>5.30</td><td v-if='fpFemaleGroup==4'>6.00</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>4.15</td><td v-if='fpFemaleGroup==2'>4.40</td><td v-if='fpFemaleGroup==3'>4.55</td><td v-if='fpFemaleGroup==4'>5.20</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>4.00</td><td v-if='fpFemaleGroup==2'>4.20</td><td v-if='fpFemaleGroup==3'>4.40</td><td v-if='fpFemaleGroup==4'>5.00</td></tr>

                            <tr><td rowspan="3">Плавание на 100 м вольным стилем (мин. с.)</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>3.20</td><td v-if='fpFemaleGroup==2'>3.25</td><td v-if='fpFemaleGroup==3'>3.40</td><td v-if='fpFemaleGroup==4'>4.20</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>2.50</td><td v-if='fpFemaleGroup==2'>3.55</td><td v-if='fpFemaleGroup==3'>3.10</td><td v-if='fpFemaleGroup==4'>3.45</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>2.30</td><td v-if='fpFemaleGroup==2'>2.36</td><td v-if='fpFemaleGroup==3'>2.50</td><td v-if='fpFemaleGroup==4'>3.20</td></tr>

                            <tr><td rowspan="3">Лыжная гонка на 5 км (мин. с.)*</td>
                                <td>«3»</td><td v-if='fpFemaleGroup==1'>39</td><td v-if='fpFemaleGroup==2'>41</td><td v-if='fpFemaleGroup==3'>43</td><td v-if='fpFemaleGroup==4'>45</td></tr>
                            <tr><td>«4»</td><td v-if='fpFemaleGroup==1'>36</td><td v-if='fpFemaleGroup==2'>38</td><td v-if='fpFemaleGroup==3'>40</td><td v-if='fpFemaleGroup==4'>42</td></tr>
                            <tr><td>«5»</td><td v-if='fpFemaleGroup==1'>34</td><td v-if='fpFemaleGroup==2'>36</td><td v-if='fpFemaleGroup==3'>38</td><td v-if='fpFemaleGroup==4'>40</td></tr>
                        </table>
                        <p v-if='fpFemaleGroup<5'>* в бесснежных районах вместо бега на лыжах сдается кросс 5 км.</p>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text >
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="age"
                                    :items="[6, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80]"
                                    filled
                                    label="Возраст"
                                    ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="first6"
                                    :items="[{value:false,text:'Нет'},{value:true,text:'Да'}]"
                                    filled
                                    label="Первые 6 месяцев службы"
                                    ></v-select>
                            </v-col>
                        </v-row>
                        <table>
                            <tr><th rowspan="2">Упражнение</th><th colspan="3">Время выполнения, сек.</th></tr>
                            <tr><th>«5»</th><th>«4»</th><th>«3»</th></tr>
                            <tr><td colspan="4">Включение в СИЗОД пожарным:</td></tr>
                            <tr><td>в КИП</td><td>{{updateNumber(55)}}</td><td>{{updateNumber(60)}}</td><td>{{updateNumber(65)}}</td></tr>
                            <tr><td>в ДАСВ</td><td>{{updateNumber(50)}}</td><td>{{updateNumber(55)}}</td><td>{{updateNumber(55)}}</td></tr>
                            <tr><td colspan="4">Включение в СИЗОД звеном ГДЗС:</td></tr>
                            <tr><td>в КИП</td><td>{{updateNumber(60)}}</td><td>{{updateNumber(65)}}</td><td>{{updateNumber(70)}}</td></tr>
                            <tr><td>в ДАСВ</td><td>{{updateNumber(55)}}</td><td>{{updateNumber(60)}}</td><td>{{updateNumber(65)}}</td></tr>

                            <tr>
                                <td colspan="4">
                                    <p><b>Условия выполнения упражнений</b></p>
                                    <ol>
                                        <li>Пожарные в специальной одежде и снаряжении находятся в одном метре от СИЗОД (лицом к ним).</li>
                                        <li>СИЗОД уложены на скамейках или специальных полках.</li>
                                        <li>Начало — поданная команда.</li>
                                        <li>Окончание — пожарные включены в СИЗОД (все этапы включения выполнены согласно требованиям Наставления по газодымозащитной службе пожарной охраны).</li>
                                    </ol>
                                </td>
                            </tr>

                            <tr><td colspan="4">Развертывание на АЦ с подачей одного ствола «Б» на:</td></tr>
                            <tr><td>2 рукава</td><td>{{updateNumber(17)}}</td><td>{{updateNumber(18)}}</td><td>{{updateNumber(20)}}</td></tr>
                            <tr><td>3 рукава</td><td>{{updateNumber(26)}}</td><td>{{updateNumber(29)}}</td><td>{{updateNumber(32)}}</td></tr>
                            <tr>
                                <td colspan="4">
                                    <p><b>Условия выполнения упражнений</b></p>
                                    <ol>
                                        <li>Двигатель автомобиля работает. Рукава находятся в ящиках и закреплены.</li>
                                        <li>Упражнение выполняется расчетом из 2 номеров (водитель и пожарный в СИЗОД), находящихся у заднего колеса автомобиля.</li>
                                        <li>Начало — поданная команда.</li>
                                        <li>Окончание — двигатель переключен на насос, рукавная линия проложена. Пожарный находится около ствола, водитель у насоса.</li>
                                    </ol>
                                    <p><b>Примечание:</b> При выполнении упражне­ния с подачей воды к норме времени прибавляется 5 с на каждый рукав. Время фиксируется в момент появления струи из ствола.</p>
                                </td>
                            </tr>

                            <tr><td>Подъём по установленной выдвижной лестнице в окно 3-го этажа учебной башни</td>
                                <td>{{updateNumber(12)}}</td><td>{{updateNumber(15)}}</td><td>{{updateNumber(18)}}</td></tr>
                            <tr>
                                <td colspan="4">
                                    <p><b>Условия выполнения упражнений</b></p>
                                    <ol>
                                        <li>Выдвижная лестница установлена и закреплена за седьмую ступеньку. Один пожарный в СИЗОД стоит около лестницы, руками держится за тетивы лестницы, левая нога на первой ступеньке. Другой пожарный, включенный в СИЗОД, стоит между стеной и лестницей и, прижимая лестницу к зданию, удерживает её.</li>
                                        <li>Начало — поданная команда.</li>
                                        <li>Окончание — первый пожарный обеими ногами коснулся пола 3-го этажа учебной башни, другой -  стоит между стеной и лестницей.</li>
                                    </ol>
                                </td>
                            </tr>

                            <tr><td colspan="4">Развертывание на АЦ с подачей одного ГПС-600 на:</td></tr>
                            <tr><td>2 рукава</td><td>{{updateNumber(17)}}</td><td>{{updateNumber(18)}}</td><td>{{updateNumber(20)}}</td></tr>
                            <tr><td>3 рукава</td><td>{{updateNumber(26)}}</td><td>{{updateNumber(29)}}</td><td>{{updateNumber(32)}}</td></tr>
                            <tr>
                                <td colspan="4">
                                    <p><b>Условия выполнения упражнений</b></p>
                                    <ol>
                                        <li>Двигатель автомобиля работает. Пожарное оборудование закреплено на своих местах.</li>
                                        <li>Упражнение выполняется расчетом из 2 номеров (водитель и пожарный в СИЗОД), находящихся у заднего колеса автомобиля</li>
                                        <li>Начало — поданная команда.</li>
                                        <li>Окончание — двигатель переключен на насос, рукавная линия проложена. Пожарный находится около ствола, водитель у насоса.</li>
                                    </ol>
                                </td>
                            </tr>
                        </table>


                    </v-card-text>
                </v-card>
            </v-tab-item>


            <v-tab-item>
                <v-card flat>

                    <v-card-title style="word-break: break-word">
                        Сборник упражнений по профессиональной подготовке личного состава федеральной противопожарной службы Государственной противопожарной службы
                    </v-card-title>
                     <v-card-subtitle>
                        Распоряжение МЧС России от 09.12.2022 № 1357 
                     </v-card-subtitle>
                    <v-card-text >
Упражнения по профессиональной подготовке - это временные, количественные и качественные показатели выполнения определенных задач, приемов и действий личным составом федеральной противопожарной службы Государственной 
противопожарной службы, отделениями, дежурными караулами (сменами) с соблюдением последовательности (порядка),
изложенных в Сборнике упражнений по профессиональной подготовке личного состава федеральной противопожарной службы 
Государственной противопожарной службы (далее - сборник упражнений). <br><br>

Упражнения по профессиональной подготовке распространяются на личный состав федеральной противопожарной службы 
Государственной противопожарной службы, участвующий и привлекаемый (допущенный) к тушению пожаров и проведению 
аварийно-спасательных работ в установленном порядке, а также на обучающихся образовательных организаций на усмотрение
руководителя (начальника) учреждения, исходя из условий безопасности и метариально-технического обеспечения в 
соответствии с разработанными локальными нормативными правовыми актами в установленном порядке. Вместе с тем, 
временные, количественные и качественные показатели упражнений не применяются для оценки качества действий 
участников тушения пожара при ведении боевых действий по тушению пожаров и проведению аварийно-спасательных работ. <br><br>

Выполнение упражнений должно сопровождаться изучением выполнения приемов, элементов и упражнений с мобильными средствами пожаротушения,
пожарным оборудованием и инструментом, средствами оказания первой помощи, приборов и средств радиационной, химической
и биологической защиты, а также требований охраны труда.    
                    </v-card-text>
                
                </v-card>
                <div align="center">
                    <v-btn
                class="ma-1 pa-1"
                outlined
                rounded
                target="_blank"
                href="https://api.learn.mchs.ru/uploads/mpk/exercises.pdf"
                download
                color="#1e88e5"
                >
             Скачать
            </v-btn>
                </div>
            </v-tab-item>















        </v-tabs-items>
    </v-container>
</template>
<style>
    #normCont table{
        margin:16px auto 10px auto;
        table-layout: auto;
    }

    #normCont table, #normCont  td,#normCont th {
        padding:5px 10px 5px 10px;
        border: 1px solid #C0C0C0;
    }

    #normCont th {
        font-size: 14px;
        border: 1px solid #adadad;
        background: #dbedf7;
    }

    #normCont th.turn {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-1);
    }
    #normCont td img {
        margin: 0;
    }
</style>
<script>
    export default {
        name: "index",
        data: () => ({
                tab: 0,
                age: 18,
                first6: false
            }),
        methods: {
            updateNumber(num) {
                var k = 0;
                if (this.age >= 30 && this.age < 40) {
                    k = k + num * 0.05
                }
                if (this.age >= 40) {
                    k = k + num * 0.1
                }
                if (this.first6) {
                    k = k + num * 0.1
                }
                return Math.floor(num + k)
            }
        },
        computed: {
            fpFemaleGroup() {
                var k = 1;
                if (this.age < 25) {
                    k = 1
                }
                if (this.age >= 25 && this.age < 30) {
                    k = 2
                }
                if (this.age >= 30 && this.age < 35) {
                    k = 3
                }
                if (this.age >= 35 && this.age < 40) {
                    k = 4
                }
                if (this.age >= 40 && this.age < 45) {
                    k = 5
                }
                if (this.age >= 45) {
                    k = 6
                }
                return k                
            },
            fpMaleGroup() {
                var k = 1;
                if (this.age < 25) {
                    k = 1
                }
                if (this.age >= 25 && this.age < 30) {
                    k = 2
                }
                if (this.age >= 30 && this.age < 35) {
                    k = 3
                }
                if (this.age >= 35 && this.age < 40) {
                    k = 4
                }
                if (this.age >= 40 && this.age < 45) {
                    k = 5
                }
                if (this.age >= 45 && this.age < 50) {
                    k = 6
                }
                if (this.age >= 50) {
                    k = 7
                }
                return k + 1
            }
        },
        mounted() {

        }

    }
</script>